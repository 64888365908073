<template>
  <div>
    <b-table
      :items="orders"
      :fields="fields"
      :noTaker="noTaker"
      striped
      responsive
      class="data-table"
    >
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(user_id)="data">
        <a :href="'/masters/client-single/'+enc(data.item.user_id)">
          {{data.value}}
        </a>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BBadge,BButton
} from 'bootstrap-vue'
import gql from 'graphql-tag'

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    gql
  },
  props:['orders', 'userNames', 'extraFields', 'noTaker'],
  data() {
    return {
      fields: ['id', {key:'amount', label: 'Valor', formatter: 'currency'},{key:'provider.code', label: 'Método'},  {key:'created_at',label: 'Aberto em', formatter: 'dateFormatter'},  {key:'tags', label: 'Info Adicional', formatter: 'pendingInfo'}, {key:'updated_at', label: 'Atualizado em', formatter: 'dateFormatter'}, { key: 'status', label: 'Status' }],
      items: [],
      status: [{
        'CONFIRMED': 'Liberado', "PENDING": 'Aguardando pg', 'CONFIRMED_WITH_OBSERVATION': 'Lib. Confiança', 'CANCELED': 'Cancelado', 'ROLLEDBACK': 'Revertido', 
      },
      {
        'CONFIRMED': 'success', "PENDING": 'info', 'CONFIRMED_WITH_OBSERVATION': 'warning', 'CANCELED': 'danger', 'ROLLEDBACK': 'danger'
      }],
    }
  },
  methods:{
    enc(id){
      return btoa(id)
    },
    emitSidebar(deposit){
      this.$emit('openSidebar', deposit)
    },
    currency(value){
      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },
    depositOwner(userId){
      return this.userNames.filter(un => un.user_id == userId)[0]?.value
    },
    pendingInfo(value){
      let val = value.filter(v => v.key == 'RELEASE_COMMENT')
      return val.length > 0 ? val[0].value : 'Sem info'
    },
    dateFormatter(value){
      return this.$datter.convert(value);
    },
    rollBackDeposit(transaction_id){
      var el = document.getElementById("release-promo");
      el.style.display = "none";
      this.toRollBack = transaction_id;
      this.$apollo.mutate({
        mutation: gql`mutation ($order_id: ID!,){
        rollBackMovement(
          order_id: $order_id,
          )
        }`,
        variables: {
          order_id: this.toRollBack,
        },
        client: "walletClient"
      }).then((data) => {
        if(data.data.rollBackMovement == true){
          this.$apollo.mutate({
            mutation: gql`mutation ($id: ID!,$status: String){
            updateOrder(
              id: $id,
              status: $status,
              ){
                id
              }
            }`,
            variables: {
              id: this.toRollBack,
              status: "ROLLEDBACK",
            },
            client: "cashioClient"
          }).then((data) => {
            alert("Depósito revertido");
            this.$router.go(this.$router.currentRoute)
          }).catch((error) => {
            console.error(error)
          })
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    checkTime(time){
      let now = new Date();
      let promo = this.$datter.rawDate(time);
      let min = parseInt(Math.abs(promo.getTime() - now.getTime()) / (1000 * 60) % 60);
      return (min <5);
    }
  },
  created(){
    if(this.extraFields != null){
      this.fields = this.extraFields
    }
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
</style>