<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Data inicial">
          <template>
            <b-form-datepicker
            v-model="from"
            locale="pt-br"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
            />
          </template>
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Data final">
          <template>
            <b-form-datepicker
              v-model="to"
              locale="pt-br"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
            />
          </template>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card :title="'Resultado: '">
          <b-row>
            <b-col class="success">
              ENTRADA:<br/>
              {{depositSum | brl}}
            </b-col>              
            <b-col class="danger">
              SAÍDA:<br/>
              {{withdrawSum | brl}}
            </b-col>        
            <b-col :class="(depositSum - withdrawSum > 0) ? 'success': 'danger'">
              TOTAL:<br/>
              {{depositSum - withdrawSum | brl}}
            </b-col>        
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <div v-if="!loading">
      <b-row v-if="deposit_orders.length >0">
        <b-col>
          <h4>Depositos</h4>
          <deposits-list
            v-if="userNames"
            :extra-fields="extraFields"
            :orders="deposit_orders"
            :user-names="userNames"
            :context-actions="'admin'"
            @openSidebar="openTransactionSidebar"
          />
        </b-col>
      </b-row>
      <b-row v-if="withdraw_orders.length >0">
        <b-col>
          <h4>Saques</h4>
          <deposits-list
            v-if="userNames"
            :extra-fields="extraFields"
            :orders="withdraw_orders"
            :user-names="userNames"
            :context-actions="'admin'"
            @openSidebar="openTransactionSidebar"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
    <edit-transaction-sidebar
      ref="transactionSidebar"
      :transaction="transactionObj"
      @refreshOrders="refreshOrders()"
    />
  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio,BFormDatepicker
} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import DepositsList from '@/components/master/transactions/DepositsList.vue'
import EditTransactionSidebar from '@/components/transactions/EditTransactionSidebar.vue'

export default {
  components: {
    BCard,
    Datepicker,
    BCardText,
    BLink,
    GeneralGroupsList,
    underscore: _,
    LogoLoaderAnimation,
    DepositsList,
    EditTransactionSidebar,
    BFormRadio,
    BCol,
    BRow,
    BFormDatepicker
  },
  data() {
    return {
      from: new Date().toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      pageToSearch: 1,
      queryResult: null,
      transactionObj: null,
      transactionStatus: 0,
      statusName: ['CONFIRMED'],
      userNames: null,
      deposit_orders:null,
      withdraw_orders:null,
      depositSum:0,
      withdrawSum:0,
      userIds:[],
      loading:false,
      extraFields: ['id', { key: 'amount', label: 'Valor', formatter: 'currency' }, { key: 'user_id', label: 'Solicitante', formatter: 'depositOwner' }, { key: 'provider.code', label: 'Método' }, { key: 'created_at', label: 'Aberto em', formatter: 'dateFormatter' }, { key: 'tags', label: 'Info Adicional', formatter: 'pendingInfo' }, { key: 'updated_at', label: 'Atualiz. em', formatter: 'dateFormatter' }, { key: 'status', label: 'Status' }],
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  watch: {
    deposit_orders(){
      this.depositSum = _.pluck(this.deposit_orders,'amount').reduce((partialSum, a) => partialSum + a, 0);
    },
    withdraw_orders(){
      this.withdrawSum = _.pluck(this.withdraw_orders,'amount').reduce((partialSum, a) => partialSum + a, 0);
    },
    from() {
      this.searchTransactions();
    },
    to() {
      this.searchTransactions();
    }
  },
  created() {
    this.searchUser()
  },
  methods: {
    openTransactionSidebar(e) {
      this.transactionObj = e
      this.$refs.transactionSidebar.toogleV()
    },
    paginate(eV) {
      if(this.pageToSearch == 1){
        this.pageToSearch = 2;
      }else{
        this.pageToSearch = this.pageToSearch + eV;
      }
    },
    searchUser(){
      this.$apollo.query({
        query: gql`query($value: String,$code: String){
          master_user_documents_by_value(
            value : $value,
            code: $code
          ){
            user_id
        }
        }`,
        variables:{
            value: '%'+this.searchQuery+'%',
            code : '%'+JSON.parse(localStorage.getItem('userData')).inviteCode+'%'
        },
        client: "identityClient"
      }).then((data) => {
        this.userIds = _.pluck(data.data.master_user_documents_by_value,'user_id');
        this.getDocs()
      }).catch((error) => {
        console.error(error)
      })
    },
    searchTransactions(){
      this.loading = true;
      this.$apollo.query({
        query: gql`query($status:String, $users:[ID!], $from: Mixed, $to: Mixed){
          orders(first:30000,users:$users,status:$status, where: {column:CREATED_AT, operator:GTE, value: $from , AND:{column:CREATED_AT, operator:LTE, value: $to}}){
            data{
              id,
              order_type,
              user_id,
              amount
              status,
              created_at,
              updated_at,
              target_bank{
                bank_name,
                owner_name,
                account,
                agency
              },
              tags{
                key,
                value
              },
              provider{
                code
              }
            },
            paginatorInfo{
              currentPage,
              lastPage
            }
          }
        }`,
        client: 'cashioClient',
        variables:{
            status: this.statusName[this.transactionStatus],
            // offset: this.pageToSearch,
            from: this.from,
            to: this.to,
            users: this.userIds,
          }
      }).then((data) => {
        // this.userIds = _.pluck(data.data.master_user_documents_by_value,'user_id');
        this.deposit_orders = data.data.orders.data.filter(o => o.order_type == "DEPOSIT");
        this.withdraw_orders = data.data.orders.data.filter(o => o.order_type == "WITHDRAW");
        this.loading = false;


      }).catch((error) => {
        console.error(error)
      })
    },
    getDocs(){
      this.$apollo.query({
        query: gql`query ($user_ids: [ID!]) {
          get_users_doc(user_ids: $user_ids, type:"FULLNAME") {
            user_id
            value
          }
        }`,
        variables: {
          user_ids: this.userIds,
        },
        client: 'identityClient',
      }).then(data => {
        this.userNames = data.data.get_users_doc
        this.searchTransactions();
      }).catch(error => {
        console.error(error)
      })
    }
  },
}
</script>

<style>
  .success{
    color:#28c76f;
  }
  .danger{
    color:#F97794;
  }
</style>
